import React, { useEffect } from 'react';
import AdminContainer from '../../../../layout/admin/container';
import { useBreadcrumbs } from '../../../../../contexts/breadcrumbs-provider';
import Heading from '../../../../layout/admin/heading';
import AutoForm from '../../../../form/auto-form';
import { navigate } from 'gatsby-link';
import SEO from '../../../../layout/seo';

const AdminChooseLeague = () => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      {
        href: '/app/admin/products',
        label: 'Products',
      },
      {
        href: `/app/admin/products/choose-league`,
        label: `Choose League`,
      },
    ]);
  }, []);


  const chooseLeagueForm = {
    type: 'get',
    successHandler: (values) => {
      // console.log(values, 'we are about to redirect');
      const { league_id: leagueId } = values;
      navigate(`/app/admin/products/league/create?id=${leagueId}`);
    },
    fields: [
      {
        name: 'league_id',
        label: 'League',
        type: 'select',
        optionsUrl: '/options/leagues',
        helpText: 'To get started, choose a league to inherit product defaults from.',
        required: true,
      },
    ],
    submitLabel: 'Create New Product',
  };

  return (
    <AdminContainer>
      <SEO title="Choose league" />
      <Heading title="Choose League" />
      <div className="max-w-2xl mx-auto py-12">
        <AutoForm {...chooseLeagueForm} />
      </div>
    </AdminContainer>
  );
}

export default AdminChooseLeague;
